import * as React from "react";
import { useEffect, useState } from "react";
import axios from "axios";
import "../fronthome/Calendar.css";
import DatePicker from "react-date-picker";
import { Button } from "@mui/material";
import { Block, CancelOutlined, EventAvailable, EventBusy, Send, NotificationAdd } from "@mui/icons-material/";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { Box } from "@mui/system";
import { Container, TextField, Grid, CssBaseline, Typography, FormControl, InputLabel, Select, MenuItem, Modal } from "@mui/material";
import Swal from "sweetalert2";
import Navbar from "./Navbar/Navbar";
import Footer from "./footer/footer";

const stylee = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 350,
  bgcolor: "white",
  boxShadow: 24,
  p: 1,
};

export default function Webcalendar() {
  // const URL = "http://192.168.4.211:4444";
  const URL = "https://api.thaipadi.org";
  const URLAlert = "https://lineapi.thaipadi.org";

  //date
  const [startDate, setStartDate] = useState(new Date());
  const [dayname, setDayname] = useState("");
  useEffect(() => {
    if (startDate.getDay() === 0) {
      setDayname("วันอาทิตย์");
      return;
    } else if (startDate.getDay() === 1) {
      setDayname("วันจันทร์");
      return;
    } else if (startDate.getDay() === 2) {
      setDayname("วันอังคาร");
      return;
    } else if (startDate.getDay() === 3) {
      setDayname("วันพุธ");
      return;
    } else if (startDate.getDay() === 4) {
      setDayname("วันพฤหัส");
      return;
    } else if (startDate.getDay() === 5) {
      setDayname("วันศุกร์");
      return;
    } else if (startDate.getDay() === 6) {
      setDayname("วันเสาร์");
    }
  }, [startDate]);
  //date

  //getdata
  const [data, setData] = useState([]);
  useEffect(() => {
    axios.get(`${URL}/users`).then((res) => {
      res.data.shift();
      res.data.shift();
      setData(res.data);
    });
  }, [URL]);
  //getdata

  //seturl,id
  const time9 = ["08:50", "9"];
  const time10 = ["09:55", "10"];
  const time11 = ["11:00", "11"];
  const time13 = ["13:30", "13"];
  const time14 = ["14:45", "14"];
  const [appUrl, setAppUrl] = useState([]);
  const [appId, setAppId] = useState([]);
  const [timeString, setTimeString] = useState([]);
  const [timeId, setTimeId] = useState([]);
  //seturl,id

  //91
  const [data91, setData91] = useState([]);
  const [reData91, setReData91] = useState([]);
  const [editButton91, setEditButton91] = useState(false);
  const [icon91, setIcon91] = useState(<EventAvailable />);
  const [status91, setStatus91] = useState("ว่าง");

  //92
  const [data92, setData92] = useState([]);
  const [reData92, setReData92] = useState([]);
  const [editButton92, setEditButton92] = useState(false);
  const [icon92, setIcon92] = useState(<EventAvailable />);
  const [status92, setStatus92] = useState("ว่าง");

  //93
  const [data93, setData93] = useState([]);
  const [reData93, setReData93] = useState([]);
  const [editButton93, setEditButton93] = useState(false);
  const [icon93, setIcon93] = useState(<EventAvailable />);
  const [status93, setStatus93] = useState("ว่าง");

  //94
  const [data94, setData94] = useState([]);
  const [reData94, setReData94] = useState([]);
  const [editButton94, setEditButton94] = useState(false);
  const [icon94, setIcon94] = useState(<EventAvailable />);
  const [status94, setStatus94] = useState("ว่าง");

  //95
  const [data95, setData95] = useState([]);
  const [reData95, setReData95] = useState([]);
  const [editButton95, setEditButton95] = useState(false);
  const [icon95, setIcon95] = useState(<EventAvailable />);
  const [status95, setStatus95] = useState("ว่าง");

  //96
  const [data96, setData96] = useState([]);
  const [reData96, setReData96] = useState([]);
  const [editButton96, setEditButton96] = useState(false);
  const [icon96, setIcon96] = useState(<EventAvailable />);
  const [status96, setStatus96] = useState("ว่าง");

  //101
  const [data101, setData101] = useState([]);
  const [reData101, setReData101] = useState([]);
  const [editButton101, setEditButton101] = useState(false);
  const [icon101, setIcon101] = useState(<EventAvailable />);
  const [status101, setStatus101] = useState("ว่าง");

  //102
  const [data102, setData102] = useState([]);
  const [reData102, setReData102] = useState([]);
  const [editButton102, setEditButton102] = useState(false);
  const [icon102, setIcon102] = useState(<EventAvailable />);
  const [status102, setStatus102] = useState("ว่าง");

  //103
  const [data103, setData103] = useState([]);
  const [reData103, setReData103] = useState([]);
  const [editButton103, setEditButton103] = useState(false);
  const [icon103, setIcon103] = useState(<EventAvailable />);
  const [status103, setStatus103] = useState("ว่าง");

  //104
  const [data104, setData104] = useState([]);
  const [reData104, setReData104] = useState([]);
  const [editButton104, setEditButton104] = useState(false);
  const [icon104, setIcon104] = useState(<EventAvailable />);
  const [status104, setStatus104] = useState("ว่าง");

  //105
  const [data105, setData105] = useState([]);
  const [reData105, setReData105] = useState([]);
  const [editButton105, setEditButton105] = useState(false);
  const [icon105, setIcon105] = useState(<EventAvailable />);
  const [status105, setStatus105] = useState("ว่าง");

  //106
  const [data106, setData106] = useState([]);
  const [reData106, setReData106] = useState([]);
  const [editButton106, setEditButton106] = useState(false);
  const [icon106, setIcon106] = useState(<EventAvailable />);
  const [status106, setStatus106] = useState("ว่าง");

  //101
  const [data111, setData111] = useState([]);
  const [reData111, setReData111] = useState([]);
  const [editButton111, setEditButton111] = useState(false);
  const [icon111, setIcon111] = useState(<EventAvailable />);
  const [status111, setStatus111] = useState("ว่าง");

  //102
  const [data112, setData112] = useState([]);
  const [reData112, setReData112] = useState([]);
  const [editButton112, setEditButton112] = useState(false);
  const [icon112, setIcon112] = useState(<EventAvailable />);
  const [status112, setStatus112] = useState("ว่าง");

  //103
  const [data113, setData113] = useState([]);
  const [reData113, setReData113] = useState([]);
  const [editButton113, setEditButton113] = useState(false);
  const [icon113, setIcon113] = useState(<EventAvailable />);
  const [status113, setStatus113] = useState("ว่าง");

  //104
  const [data114, setData114] = useState([]);
  const [reData114, setReData114] = useState([]);
  const [editButton114, setEditButton114] = useState(false);
  const [icon114, setIcon114] = useState(<EventAvailable />);
  const [status114, setStatus114] = useState("ว่าง");

  //105
  const [data115, setData115] = useState([]);
  const [reData115, setReData115] = useState([]);
  const [editButton115, setEditButton115] = useState(false);
  const [icon115, setIcon115] = useState(<EventAvailable />);
  const [status115, setStatus115] = useState("ว่าง");

  //106
  const [data116, setData116] = useState([]);
  const [reData116, setReData116] = useState([]);
  const [editButton116, setEditButton116] = useState(false);
  const [icon116, setIcon116] = useState(<EventAvailable />);
  const [status116, setStatus116] = useState("ว่าง");

  //131
  const [data131, setData131] = useState([]);
  const [reData131, setReData131] = useState([]);
  const [editButton131, setEditButton131] = useState(false);
  const [icon131, setIcon131] = useState(<EventAvailable />);
  const [status131, setStatus131] = useState("ว่าง");

  //132
  const [data132, setData132] = useState([]);
  const [reData132, setReData132] = useState([]);
  const [editButton132, setEditButton132] = useState(false);
  const [icon132, setIcon132] = useState(<EventAvailable />);
  const [status132, setStatus132] = useState("ว่าง");

  //133
  const [data133, setData133] = useState([]);
  const [reData133, setReData133] = useState([]);
  const [editButton133, setEditButton133] = useState(false);
  const [icon133, setIcon133] = useState(<EventAvailable />);
  const [status133, setStatus133] = useState("ว่าง");

  //134
  const [data134, setData134] = useState([]);
  const [reData134, setReData134] = useState([]);
  const [editButton134, setEditButton134] = useState(false);
  const [icon134, setIcon134] = useState(<EventAvailable />);
  const [status134, setStatus134] = useState("ว่าง");

  //135
  const [data135, setData135] = useState([]);
  const [reData135, setReData135] = useState([]);
  const [editButton135, setEditButton135] = useState(false);
  const [icon135, setIcon135] = useState(<EventAvailable />);
  const [status135, setStatus135] = useState("ว่าง");

  //136
  const [reData136, setReData136] = useState([]);
  const [data136, setData136] = useState([]);
  const [editButton136, setEditButton136] = useState(false);
  const [icon136, setIcon136] = useState(<EventAvailable />);
  const [status136, setStatus136] = useState("ว่าง");

  //141
  const [data141, setData141] = useState([]);
  const [reData141, setReData141] = useState([]);
  const [editButton141, setEditButton141] = useState(false);
  const [icon141, setIcon141] = useState(<EventAvailable />);
  const [status141, setStatus141] = useState("ว่าง");

  //142
  const [data142, setData142] = useState([]);
  const [reData142, setReData142] = useState([]);
  const [editButton142, setEditButton142] = useState(false);
  const [icon142, setIcon142] = useState(<EventAvailable />);
  const [status142, setStatus142] = useState("ว่าง");

  //143
  const [data143, setData143] = useState([]);
  const [reData143, setReData143] = useState([]);
  const [editButton143, setEditButton143] = useState(false);
  const [icon143, setIcon143] = useState(<EventAvailable />);
  const [status143, setStatus143] = useState("ว่าง");

  //144
  const [data144, setData144] = useState([]);
  const [reData144, setReData144] = useState([]);
  const [editButton144, setEditButton144] = useState(false);
  const [icon144, setIcon144] = useState(<EventAvailable />);
  const [status144, setStatus144] = useState("ว่าง");

  //145
  const [data145, setData145] = useState([]);
  const [reData145, setReData145] = useState([]);
  const [editButton145, setEditButton145] = useState(false);
  const [icon145, setIcon145] = useState(<EventAvailable />);
  const [status145, setStatus145] = useState("ว่าง");

  //146
  const [reData146, setReData146] = useState([]);
  const [data146, setData146] = useState([]);
  const [editButton146, setEditButton146] = useState(false);
  const [icon146, setIcon146] = useState(<EventAvailable />);
  const [status146, setStatus146] = useState("ว่าง");

  //api

  const getdata = async () => {
    try {
      const date = `${startDate.getDate()}-${startDate.getMonth() + 1}-${startDate.getFullYear()}`;

      const appoinment = [
        { name: "appoinment", id: "9" },
        { name: "appoinments", id: "9" },
        { name: "appoinmentss", id: "9" },
        { name: "appoinmentsss", id: "9" },
        { name: "appoinmentssss", id: "9" },
        { name: "appoinmentsssss", id: "9" },
        { name: "appoinment", id: "10" },
        { name: "appoinments", id: "10" },
        { name: "appoinmentss", id: "10" },
        { name: "appoinmentsss", id: "10" },
        { name: "appoinmentssss", id: "10" },
        { name: "appoinmentsssss", id: "10" },
        { name: "appoinment", id: "11" },
        { name: "appoinments", id: "11" },
        { name: "appoinmentss", id: "11" },
        { name: "appoinmentsss", id: "11" },
        { name: "appoinmentssss", id: "11" },
        { name: "appoinmentsssss", id: "11" },
        { name: "appoinment", id: "13" },
        { name: "appoinments", id: "13" },
        { name: "appoinmentss", id: "13" },
        { name: "appoinmentsss", id: "13" },
        { name: "appoinmentssss", id: "13" },
        { name: "appoinmentsssss", id: "13" },
        { name: "appoinment", id: "14" },
        { name: "appoinments", id: "14" },
        { name: "appoinmentss", id: "14" },
        { name: "appoinmentsss", id: "14" },
        { name: "appoinmentssss", id: "14" },
        { name: "appoinmentsssss", id: "14" },
      ];

      const api = appoinment.map((item) => {
        return `${URL}/${item.name}/${item.id}/${startDate.getDate()}-${startDate.getMonth() + 1}-${startDate.getFullYear()}`;
      });

      const [res91, res92, res93, res94, res95, res96, res101, res102, res103, res104, res105, res106, res111, res112, res113, res114, res115, res116, res131, res132, res133, res134, res135, res136, res141, res142, res143, res144, res145, res146] = await Promise.all([
        axios.get(api[0]),
        axios.get(api[1]),
        axios.get(api[2]),
        axios.get(api[3]),
        axios.get(api[4]),
        axios.get(api[5]),
        axios.get(api[6]),
        axios.get(api[7]),
        axios.get(api[8]),
        axios.get(api[9]),
        axios.get(api[10]),
        axios.get(api[11]),
        axios.get(api[12]),
        axios.get(api[13]),
        axios.get(api[14]),
        axios.get(api[15]),
        axios.get(api[16]),
        axios.get(api[17]),
        axios.get(api[18]),
        axios.get(api[19]),
        axios.get(api[20]),
        axios.get(api[21]),
        axios.get(api[22]),
        axios.get(api[23]),
        axios.get(api[24]),
        axios.get(api[25]),
        axios.get(api[26]),
        axios.get(api[27]),
        axios.get(api[28]),
        axios.get(api[29]),
      ]);
      const setdata = (res, id, time, TN) => {
        const defaultdata = [
          {
            AN: null,
            TN: null,
            fname: "ว่าง",
            lname: null,
            service: "ว่าง",
            provider: "หญิง",
            date: `${startDate.getDate()}-${startDate.getMonth() + 1}-${startDate.getFullYear()}`,
            time: time,
            prefix: null,
          },
          {
            fname: null,
            lname: null,
            service: null,
            provider: null,
            date: null,
            time: null,
            prefix: null,
          },
          {
            AN: null,
            TN: null,
            fname: "ปิดบริการ",
            lname: "วันหยุดราชการ",
            service: "วันหยุดราชการหรืออื่นๆ",
            provider: "หญิง",
            date: `${startDate.getDate()}-${startDate.getMonth() + 1}-${startDate.getFullYear()}`,
            time: time,
            prefix: null,
          },
          {
            AN: null,
            TN: null,
            fname: "ว่าง (เฉพาะสิทธิ์สวัสดิการข้าราชการ)",
            lname: null,
            service: "ว่าง",
            provider: "หญิง",
            date: `${startDate.getDate()}-${startDate.getMonth() + 1}-${startDate.getFullYear()}`,
            time: time,
            prefix: null,
          },
          {
            AN: null,
            TN: null,
            fname: "ไม่ประสงค์เผยแพร่ชื่อสกุล",
            lname: null,
            service: "ไม่ว่าง",
            provider: "หญิง",
            date: `${startDate.getDate()}-${startDate.getMonth() + 1}-${startDate.getFullYear()}`,
            time: time,
            prefix: null,
          },
        ];
        const holidaysURL = `${URL}/holidays`;
        switch (id) {
          case "91":
            if (res.data.length === 0) {
              if (startDate.getDay() === 0 || startDate.getDay() === 6) {
                setData91(defaultdata[3]);
                setEditButton91(false);
                setStatus91("ว่าง");
                return;
              }
              setData91(defaultdata[0]);
              setEditButton91(false);
              setStatus91("ว่าง");
              return;
            } else if (res.data.length >= 1) {
              if(res.data[0].type == 1){
                setData91(defaultdata[4]);
                setEditButton91(true);
                setIcon91(<EventBusy />);
                setStatus91("ไม่ว่าง");
                return;
              }
              setData91(res.data[0]);
              setEditButton91(true);
              setIcon91(<EventBusy />);
              setStatus91("ไม่ว่าง");
              return;
            }
            break;
          case "92":
            axios.get(`${holidaysURL}`).then((resdata) => {
              const holidaysearch = startDate.toLocaleDateString("th-TH", {
                year: "numeric",
                month: "long",
                day: "numeric",
              });
              const holidays = resdata.data.map((item) => item.date);
              const arrayIncludes = holidays.includes(holidaysearch);
              if (arrayIncludes !== true) {
                if (res.data.length === 0) {
                  if (startDate.getDay() === 0 || startDate.getDay() === 6) {
                    setData92(defaultdata[3]);
                    setEditButton92(false);
                    setStatus92("ว่าง");
                    return;
                  }
                  setData92(defaultdata[0]);
                  setEditButton92(false);
                  setStatus92("ว่าง");
                  return;
                } else if (res.data.length >= 1) {
                  if(res.data[0].type == 1){
                    setData92(defaultdata[4]);
                    setEditButton92(true);
                    setIcon92(<EventBusy />);
                    setStatus92("ไม่ว่าง");
                    return;
                  }
                  setData92(res.data[0]);
                  setEditButton92(true);
                  setIcon92(<EventBusy />);
                  setStatus92("ไม่ว่าง");
                  return;
                }
              } else {
                setData92(defaultdata[2]);
                setEditButton92(true);
                setIcon92(<EventBusy />);
                setStatus92("ไม่ว่าง");
                return;
              }
            });
            break;
          case "93":
            axios.get(`${holidaysURL}`).then((resdata) => {
              const holidaysearch = startDate.toLocaleDateString("th-TH", {
                year: "numeric",
                month: "long",
                day: "numeric",
              });
              const holidays = resdata.data.map((item) => item.date);
              const arrayIncludes = holidays.includes(holidaysearch);
              if (arrayIncludes !== true) {
                if (res.data.length === 0) {
                  if (startDate.getDay() === 0 || startDate.getDay() === 6) {
                    setData93(defaultdata[1]);
                    setEditButton93(true);
                    setIcon93(<EventBusy />);
                    setStatus93("ไม่ว่าง");
                    return;
                  } else {
                    setData93(defaultdata[0]);
                    setEditButton93(false);
                    setStatus93("ว่าง");
                    return;
                  }
                } else if (res.data.length >= 1) {
                  if(res.data[0].type == 1){
                    setData93(defaultdata[4]);
                    setEditButton93(true);
                    setIcon93(<EventBusy />);
                    setStatus93("ไม่ว่าง");
                    return;
                  }
                  setData93(res.data[0]);
                  setEditButton93(true);
                  setStatus93("ไม่ว่าง");
                  setIcon93(<EventBusy />);
                }
              } else {
                setData93(defaultdata[2]);
                setEditButton93(true);
                setIcon93(<EventBusy />);
                setStatus93("ไม่ว่าง");
                return;
              }
            });
            break;
          case "94":
            axios.get(`${holidaysURL}`).then((resdata) => {
              const holidaysearch = startDate.toLocaleDateString("th-TH", {
                year: "numeric",
                month: "long",
                day: "numeric",
              });
              const holidays = resdata.data.map((item) => item.date);
              const arrayIncludes = holidays.includes(holidaysearch);
              if (arrayIncludes !== true) {
                if (res.data.length === 0) {
                  if (startDate.getDay() === 0 || startDate.getDay() === 6) {
                    setData94(defaultdata[1]);
                    setEditButton94(true);
                    setIcon94(<EventBusy />);
                    setStatus94("ไม่ว่าง");
                    return;
                  } else {
                    setData94(defaultdata[0]);
                    setEditButton94(false);
                    setStatus94("ว่าง");
                    return;
                  }
                } else if (res.data.length >= 1) {
                  if(res.data[0].type == 1){
                    setData94(defaultdata[4]);
                    setEditButton94(true);
                    setIcon94(<EventBusy />);
                    setStatus94("ไม่ว่าง");
                    return;
                  }
                  setData94(res.data[0]);
                  setEditButton94(true);
                  setStatus94("ไม่ว่าง");
                  setIcon94(<EventBusy />);
                }
              } else {
                setData94(defaultdata[2]);
                setEditButton94(true);
                setIcon94(<EventBusy />);
                setStatus94("ไม่ว่าง");
                return;
              }
            });
            break;
          case "95":
            axios.get(`${holidaysURL}`).then((resdata) => {
              const holidaysearch = startDate.toLocaleDateString("th-TH", {
                year: "numeric",
                month: "long",
                day: "numeric",
              });
              const holidays = resdata.data.map((item) => item.date);
              const arrayIncludes = holidays.includes(holidaysearch);
              if (arrayIncludes !== true) {
                if (res.data.length === 0) {
                  if (startDate.getDay() === 0 || startDate.getDay() === 6) {
                    setData95(defaultdata[1]);
                    setEditButton95(true);
                    setIcon95(<EventBusy />);
                    setStatus95("ไม่ว่าง");
                    return;
                  } else {
                    setData95(defaultdata[0]);
                    setEditButton95(false);
                    setStatus95("ว่าง");
                    return;
                  }
                } else if (res.data.length >= 1) {
                  if(res.data[0].type == 1){
                    setData95(defaultdata[4]);
                    setEditButton95(true);
                    setIcon95(<EventBusy />);
                    setStatus95("ไม่ว่าง");
                    return;
                  }
                  setData95(res.data[0]);
                  setEditButton95(true);
                  setStatus95("ไม่ว่าง");
                  setIcon95(<EventBusy />);
                }
              } else {
                setData95(defaultdata[2]);
                setEditButton95(true);
                setIcon95(<EventBusy />);
                setStatus95("ไม่ว่าง");
                return;
              }
            });
            break;
          case "96":
            axios.get(`${holidaysURL}`).then((resdata) => {
              const holidaysearch = startDate.toLocaleDateString("th-TH", {
                year: "numeric",
                month: "long",
                day: "numeric",
              });
              const holidays = resdata.data.map((item) => item.date);
              const arrayIncludes = holidays.includes(holidaysearch);
              if (arrayIncludes !== true) {
                if (res.data.length === 0) {
                  if (startDate.getDay() === 0 || startDate.getDay() === 6) {
                    setData96(defaultdata[1]);
                    setEditButton96(true);
                    setIcon96(<EventBusy />);
                    setStatus96("ไม่ว่าง");
                    return;
                  } else {
                    setData96(defaultdata[0]);
                    setEditButton96(false);
                    setStatus96("ว่าง");
                    return;
                  }
                } else if (res.data.length >= 1) {
                  if(res.data[0].type == 1){
                    setData96(defaultdata[4]);
                    setEditButton96(true);
                    setIcon96(<EventBusy />);
                    setStatus96("ไม่ว่าง");
                    return;
                  }
                  setData96(res.data[0]);
                  setEditButton96(true);
                  setStatus96("ไม่ว่าง");
                  setIcon96(<EventBusy />);
                }
              } else {
                setData96(defaultdata[2]);
                setEditButton96(true);
                setIcon96(<EventBusy />);
                setStatus96("ไม่ว่าง");
                return;
              }
            });
            break;
          case "101":
            axios.get(`${holidaysURL}`).then((resdata) => {
              const holidaysearch = startDate.toLocaleDateString("th-TH", {
                year: "numeric",
                month: "long",
                day: "numeric",
              });
              const holidays = resdata.data.map((item) => item.date);
              const arrayIncludes = holidays.includes(holidaysearch);
              if (arrayIncludes !== true) {
                if (res.data.length === 0) {
                  if (startDate.getDay() === 0 || startDate.getDay() === 6) {
                    setData101(defaultdata[3]);
                    setEditButton101(false);
                    setStatus101("ว่าง");
                    return;
                  }
                  setData101(defaultdata[0]);
                  setEditButton101(false);
                  setStatus101("ว่าง");
                  return;
                } else if (res.data.length >= 1) {
                  if(res.data[0].type == 1){
                    setData101(defaultdata[4]);
                    setEditButton101(true);
                    setIcon101(<EventBusy />);
                    setStatus101("ไม่ว่าง");
                    return;
                  }
                  setData101(res.data[0]);
                  setEditButton101(true);
                  setIcon101(<EventBusy />);
                  setStatus101("ไม่ว่าง");
                  return;
                }
              } else {
                setData101(defaultdata[2]);
                setEditButton101(true);
                setIcon101(<EventBusy />);
                setStatus101("ไม่ว่าง");
                return;
              }
            });
            break;
          case "102":
            axios.get(`${holidaysURL}`).then((resdata) => {
              const holidaysearch = startDate.toLocaleDateString("th-TH", {
                year: "numeric",
                month: "long",
                day: "numeric",
              });
              const holidays = resdata.data.map((item) => item.date);
              const arrayIncludes = holidays.includes(holidaysearch);
              if (arrayIncludes !== true) {
                if (res.data.length === 0) {
                  if (startDate.getDay() === 0 || startDate.getDay() === 6) {
                    setData102(defaultdata[3]);
                    setEditButton102(false);
                    setStatus102("ว่าง");
                    return;
                  }
                  setData102(defaultdata[0]);
                  setEditButton102(false);
                  setStatus102("ว่าง");
                  return;
                } else if (res.data.length >= 1) {
                  if(res.data[0].type == 1){
                    setData102(defaultdata[4]);
                    setEditButton102(true);
                    setIcon102(<EventBusy />);
                    setStatus102("ไม่ว่าง");
                    return;
                  }
                  setData102(res.data[0]);
                  setEditButton102(true);
                  setIcon102(<EventBusy />);
                  setStatus102("ไม่ว่าง");
                  return;
                }
              } else {
                setData102(defaultdata[2]);
                setEditButton102(true);
                setIcon102(<EventBusy />);
                setStatus102("ไม่ว่าง");
                return;
              }
            });
            break;
          case "103":
            axios.get(`${holidaysURL}`).then((resdata) => {
              const holidaysearch = startDate.toLocaleDateString("th-TH", {
                year: "numeric",
                month: "long",
                day: "numeric",
              });
              const holidays = resdata.data.map((item) => item.date);
              const arrayIncludes = holidays.includes(holidaysearch);
              if (arrayIncludes !== true) {
                if (res.data.length === 0) {
                  if (startDate.getDay() === 0 || startDate.getDay() === 6) {
                    setData103(defaultdata[1]);
                    setEditButton103(true);
                    setIcon103(<EventBusy />);
                    setStatus103("ไม่ว่าง");
                    return;
                  } else {
                    setData103(defaultdata[0]);
                    setEditButton103(false);
                    setStatus103("ว่าง");
                    return;
                  }
                } else if (res.data.length >= 1) {
                  if(res.data[0].type == 1){
                    setData103(defaultdata[4]);
                    setEditButton103(true);
                    setIcon103(<EventBusy />);
                    setStatus103("ไม่ว่าง");
                    return;
                  }
                  setData103(res.data[0]);
                  setEditButton103(true);
                  setStatus103("ไม่ว่าง");
                  setIcon103(<EventBusy />);
                }
              } else {
                setData103(defaultdata[2]);
                setEditButton103(true);
                setIcon103(<EventBusy />);
                setStatus103("ไม่ว่าง");
                return;
              }
            });
            break;
          case "104":
            axios.get(`${holidaysURL}`).then((resdata) => {
              const holidaysearch = startDate.toLocaleDateString("th-TH", {
                year: "numeric",
                month: "long",
                day: "numeric",
              });
              const holidays = resdata.data.map((item) => item.date);
              const arrayIncludes = holidays.includes(holidaysearch);
              if (arrayIncludes !== true) {
                if (res.data.length === 0) {
                  if (startDate.getDay() === 0 || startDate.getDay() === 6) {
                    setData104(defaultdata[1]);
                    setEditButton104(true);
                    setIcon104(<EventBusy />);
                    setStatus104("ไม่ว่าง");
                    return;
                  } else {
                    setData104(defaultdata[0]);
                    setEditButton104(false);
                    setStatus104("ว่าง");
                    return;
                  }
                } else if (res.data.length >= 1) {
                  if(res.data[0].type == 1){
                    setData104(defaultdata[4]);
                    setEditButton104(true);
                    setIcon104(<EventBusy />);
                    setStatus104("ไม่ว่าง");
                    return;
                  }
                  setData104(res.data[0]);
                  setEditButton104(true);
                  setStatus104("ไม่ว่าง");
                  setIcon104(<EventBusy />);
                }
              } else {
                setData104(defaultdata[2]);
                setEditButton104(true);
                setIcon104(<EventBusy />);
                setStatus104("ไม่ว่าง");
                return;
              }
            });
            break;
          case "105":
            axios.get(`${holidaysURL}`).then((resdata) => {
              const holidaysearch = startDate.toLocaleDateString("th-TH", {
                year: "numeric",
                month: "long",
                day: "numeric",
              });
              const holidays = resdata.data.map((item) => item.date);
              const arrayIncludes = holidays.includes(holidaysearch);
              if (arrayIncludes !== true) {
                if (res.data.length === 0) {
                  if (startDate.getDay() === 0 || startDate.getDay() === 6) {
                    setData105(defaultdata[1]);
                    setEditButton105(true);
                    setIcon105(<EventBusy />);
                    setStatus105("ไม่ว่าง");
                    return;
                  } else {
                    setData105(defaultdata[0]);
                    setEditButton105(false);
                    setStatus105("ว่าง");
                    return;
                  }
                } else if (res.data.length >= 1) {
                  if(res.data[0].type == 1){
                    setData105(defaultdata[4]);
                    setEditButton105(true);
                    setIcon105(<EventBusy />);
                    setStatus105("ไม่ว่าง");
                    return;
                  }
                  setData105(res.data[0]);
                  setEditButton105(true);
                  setStatus105("ไม่ว่าง");
                  setIcon105(<EventBusy />);
                }
              } else {
                setData105(defaultdata[2]);
                setEditButton105(true);
                setIcon105(<EventBusy />);
                setStatus105("ไม่ว่าง");
                return;
              }
            });
            break;
          case "106":
            axios.get(`${holidaysURL}`).then((resdata) => {
              const holidaysearch = startDate.toLocaleDateString("th-TH", {
                year: "numeric",
                month: "long",
                day: "numeric",
              });
              const holidays = resdata.data.map((item) => item.date);
              const arrayIncludes = holidays.includes(holidaysearch);
              if (arrayIncludes !== true) {
                if (res.data.length === 0) {
                  if (startDate.getDay() === 0 || startDate.getDay() === 6) {
                    setData106(defaultdata[1]);
                    setEditButton106(true);
                    setIcon106(<EventBusy />);
                    setStatus106("ไม่ว่าง");
                    return;
                  } else {
                    setData106(defaultdata[0]);
                    setEditButton106(false);
                    setStatus106("ว่าง");
                    return;
                  }
                } else if (res.data.length >= 1) {
                  if(res.data[0].type == 1){
                    setData106(defaultdata[4]);
                    setEditButton106(true);
                    setIcon106(<EventBusy />);
                    setStatus106("ไม่ว่าง");
                    return;
                  }
                  setData106(res.data[0]);
                  setEditButton106(true);
                  setStatus106("ไม่ว่าง");
                  setIcon106(<EventBusy />);
                }
              } else {
                setData106(defaultdata[2]);
                setEditButton106(true);
                setIcon106(<EventBusy />);
                setStatus106("ไม่ว่าง");
                return;
              }
            });
            break;
          case "111":
            axios.get(`${holidaysURL}`).then((resdata) => {
              const holidaysearch = startDate.toLocaleDateString("th-TH", {
                year: "numeric",
                month: "long",
                day: "numeric",
              });
              const holidays = resdata.data.map((item) => item.date);
              const arrayIncludes = holidays.includes(holidaysearch);
              if (arrayIncludes !== true) {
                if (res.data.length === 0) {
                  if (startDate.getDay() === 0 || startDate.getDay() === 6) {
                    setData111(defaultdata[3]);
                    setEditButton111(false);
                    setStatus111("ว่าง");
                    return;
                  }
                  setData111(defaultdata[0]);
                  setEditButton111(false);
                  setStatus111("ว่าง");
                  return;
                } else if (res.data.length >= 1) {
                  if(res.data[0].type == 1){
                    setData111(defaultdata[4]);
                    setEditButton111(true);
                    setIcon111(<EventBusy />);
                    setStatus111("ไม่ว่าง");
                    return;
                  }
                  setData111(res.data[0]);
                  setEditButton111(true);
                  setIcon111(<EventBusy />);
                  setStatus111("ไม่ว่าง");
                  return;
                }
              } else {
                setData111(defaultdata[2]);
                setEditButton111(true);
                setIcon111(<EventBusy />);
                setStatus111("ไม่ว่าง");
                return;
              }
            });
            break;
          case "112":
            axios.get(`${holidaysURL}`).then((resdata) => {
              const holidaysearch = startDate.toLocaleDateString("th-TH", {
                year: "numeric",
                month: "long",
                day: "numeric",
              });
              const holidays = resdata.data.map((item) => item.date);
              const arrayIncludes = holidays.includes(holidaysearch);
              if (arrayIncludes !== true) {
                if (res.data.length === 0) {
                  if (startDate.getDay() === 0 || startDate.getDay() === 6) {
                    setData112(defaultdata[3]);
                    setEditButton112(false);
                    setStatus112("ว่าง");
                    return;
                  }
                  setData112(defaultdata[0]);
                  setEditButton112(false);
                  setStatus112("ว่าง");
                  return;
                } else if (res.data.length >= 1) {
                  if(res.data[0].type == 1){
                    setData112(defaultdata[4]);
                    setEditButton112(true);
                    setIcon112(<EventBusy />);
                    setStatus112("ไม่ว่าง");
                    return;
                  }
                  setData112(res.data[0]);
                  setEditButton112(true);
                  setIcon112(<EventBusy />);
                  setStatus112("ไม่ว่าง");
                  return;
                }
              } else {
                setData112(defaultdata[2]);
                setEditButton112(true);
                setIcon112(<EventBusy />);
                setStatus112("ไม่ว่าง");
                return;
              }
            });
            break;
          case "113":
            axios.get(`${holidaysURL}`).then((resdata) => {
              const holidaysearch = startDate.toLocaleDateString("th-TH", {
                year: "numeric",
                month: "long",
                day: "numeric",
              });
              const holidays = resdata.data.map((item) => item.date);
              const arrayIncludes = holidays.includes(holidaysearch);
              if (arrayIncludes !== true) {
                if (res.data.length === 0) {
                  if (startDate.getDay() === 0 || startDate.getDay() === 6) {
                    setData113(defaultdata[3]);
                    setEditButton113(false);
                    setStatus113("ว่าง");
                    return;
                  }
                  setData113(defaultdata[0]);
                  setEditButton113(false);
                  setStatus113("ว่าง");
                  return;
                } else if (res.data.length >= 1) {
                  if(res.data[0].type == 1){
                    setData113(defaultdata[4]);
                    setEditButton113(true);
                    setIcon113(<EventBusy />);
                    setStatus113("ไม่ว่าง");
                    return;
                  }
                  setData113(res.data[0]);
                  setEditButton113(true);
                  setStatus113("ไม่ว่าง");
                  setIcon113(<EventBusy />);
                }
              } else {
                setData113(defaultdata[2]);
                setEditButton113(true);
                setIcon113(<EventBusy />);
                setStatus113("ไม่ว่าง");
                return;
              }
            });
            break;
          case "114":
            axios.get(`${holidaysURL}`).then((resdata) => {
              const holidaysearch = startDate.toLocaleDateString("th-TH", {
                year: "numeric",
                month: "long",
                day: "numeric",
              });
              const holidays = resdata.data.map((item) => item.date);
              const arrayIncludes = holidays.includes(holidaysearch);
              if (arrayIncludes !== true) {
                if (res.data.length === 0) {
                  if (startDate.getDay() === 0 || startDate.getDay() === 6) {
                    setData114(defaultdata[1]);
                    setEditButton114(true);
                    setIcon114(<EventBusy />);
                    setStatus114("ไม่ว่าง");
                    return;
                  } else {
                    setData114(defaultdata[0]);
                    setEditButton114(false);
                    setStatus114("ว่าง");
                    return;
                  }
                } else if (res.data.length >= 1) {
                  if(res.data[0].type == 1){
                    setData114(defaultdata[4]);
                    setEditButton114(true);
                    setIcon114(<EventBusy />);
                    setStatus114("ไม่ว่าง");
                    return;
                  }
                  setData114(res.data[0]);
                  setEditButton114(true);
                  setStatus114("ไม่ว่าง");
                  setIcon114(<EventBusy />);
                }
              } else {
                setData114(defaultdata[2]);
                setEditButton114(true);
                setIcon114(<EventBusy />);
                setStatus114("ไม่ว่าง");
                return;
              }
            });
            break;
          case "115":
            axios.get(`${holidaysURL}`).then((resdata) => {
              const holidaysearch = startDate.toLocaleDateString("th-TH", {
                year: "numeric",
                month: "long",
                day: "numeric",
              });
              const holidays = resdata.data.map((item) => item.date);
              const arrayIncludes = holidays.includes(holidaysearch);
              if (arrayIncludes !== true) {
                if (res.data.length === 0) {
                  if (startDate.getDay() === 0 || startDate.getDay() === 6) {
                    setData115(defaultdata[1]);
                    setEditButton115(true);
                    setIcon115(<EventBusy />);
                    setStatus115("ไม่ว่าง");
                    return;
                  } else {
                    setData115(defaultdata[0]);
                    setEditButton115(false);
                    setStatus115("ว่าง");
                    return;
                  }
                } else if (res.data.length >= 1) {
                  if(res.data[0].type == 1){
                    setData115(defaultdata[4]);
                    setEditButton115(true);
                    setIcon115(<EventBusy />);
                    setStatus115("ไม่ว่าง");
                    return;
                  }
                  setData115(res.data[0]);
                  setEditButton115(true);
                  setStatus115("ไม่ว่าง");
                  setIcon115(<EventBusy />);
                }
              } else {
                setData115(defaultdata[2]);
                setEditButton115(true);
                setIcon115(<EventBusy />);
                setStatus115("ไม่ว่าง");
                return;
              }
            });
            break;
          case "116":
            axios.get(`${holidaysURL}`).then((resdata) => {
              const holidaysearch = startDate.toLocaleDateString("th-TH", {
                year: "numeric",
                month: "long",
                day: "numeric",
              });
              const holidays = resdata.data.map((item) => item.date);
              const arrayIncludes = holidays.includes(holidaysearch);
              if (arrayIncludes !== true) {
                if (res.data.length === 0) {
                  if (startDate.getDay() === 0 || startDate.getDay() === 6) {
                    setData116(defaultdata[1]);
                    setEditButton116(true);
                    setIcon116(<EventBusy />);
                    setStatus116("ไม่ว่าง");
                    return;
                  } else {
                    setData116(defaultdata[0]);
                    setEditButton116(false);
                    setStatus116("ว่าง");
                    return;
                  }
                } else if (res.data.length >= 1) {
                  if(res.data[0].type == 1){
                    setData116(defaultdata[4]);
                    setEditButton116(true);
                    setIcon116(<EventBusy />);
                    setStatus116("ไม่ว่าง");
                    return;
                  }
                  setData116(res.data[0]);
                  setEditButton116(true);
                  setStatus116("ไม่ว่าง");
                  setIcon116(<EventBusy />);
                }
              } else {
                setData116(defaultdata[2]);
                setEditButton116(true);
                setIcon116(<EventBusy />);
                setStatus116("ไม่ว่าง");
                return;
              }
            });
            break;
          case "131":
            axios.get(`${holidaysURL}`).then((resdata) => {
              const holidaysearch = startDate.toLocaleDateString("th-TH", {
                year: "numeric",
                month: "long",
                day: "numeric",
              });
              const holidays = resdata.data.map((item) => item.date);
              const arrayIncludes = holidays.includes(holidaysearch);
              if (arrayIncludes !== true) {
                if (res.data.length === 0) {
                  if (startDate.getDay() === 0 || startDate.getDay() === 6) {
                    setData131(defaultdata[3]);
                    setEditButton131(false);
                    setStatus131("ว่าง");
                    return;
                  }
                  setData131(defaultdata[0]);
                  setEditButton131(false);
                  setStatus131("ว่าง");
                  return;
                } else if (res.data.length >= 1) {
                  if(res.data[0].type == 1){
                    setData131(defaultdata[4]);
                    setEditButton131(true);
                    setIcon131(<EventBusy />);
                    setStatus131("ไม่ว่าง");
                    return;
                  }
                  setData131(res.data[0]);
                  setEditButton131(true);
                  setIcon131(<EventBusy />);
                  setStatus131("ไม่ว่าง");
                  return;
                }
              } else {
                setData131(defaultdata[2]);
                setEditButton131(true);
                setIcon131(<EventBusy />);
                setStatus131("ไม่ว่าง");
                return;
              }
            });
            break;
          case "132":
            axios.get(`${holidaysURL}`).then((resdata) => {
              const holidaysearch = startDate.toLocaleDateString("th-TH", {
                year: "numeric",
                month: "long",
                day: "numeric",
              });
              const holidays = resdata.data.map((item) => item.date);
              const arrayIncludes = holidays.includes(holidaysearch);
              if (arrayIncludes !== true) {
                if (res.data.length === 0) {
                  if (startDate.getDay() === 0 || startDate.getDay() === 6) {
                    setData132(defaultdata[3]);
                    setEditButton132(false);
                    setStatus132("ว่าง");
                    return;
                  }
                  setData132(defaultdata[0]);
                  setEditButton132(false);
                  setStatus132("ว่าง");
                  return;
                } else if (res.data.length >= 1) {
                  if(res.data[0].type == 1){
                    setData132(defaultdata[4]);
                    setEditButton132(true);
                    setIcon132(<EventBusy />);
                    setStatus132("ไม่ว่าง");
                    return;
                  }
                  setData132(res.data[0]);
                  setEditButton132(true);
                  setIcon132(<EventBusy />);
                  setStatus132("ไม่ว่าง");
                  return;
                }
              } else {
                setData132(defaultdata[2]);
                setEditButton132(true);
                setIcon132(<EventBusy />);
                setStatus132("ไม่ว่าง");
                return;
              }
            });
            break;
          case "133":
            axios.get(`${holidaysURL}`).then((resdata) => {
              const holidaysearch = startDate.toLocaleDateString("th-TH", {
                year: "numeric",
                month: "long",
                day: "numeric",
              });
              const holidays = resdata.data.map((item) => item.date);
              const arrayIncludes = holidays.includes(holidaysearch);
              if (arrayIncludes !== true) {
                if (res.data.length === 0) {
                  if (startDate.getDay() === 0 || startDate.getDay() === 6) {
                    setData133(defaultdata[1]);
                    setEditButton133(true);
                    setIcon133(<EventBusy />);
                    setStatus133("ไม่ว่าง");
                    return;
                  } else {
                    setData133(defaultdata[0]);
                    setEditButton133(false);
                    setStatus133("ว่าง");
                    return;
                  }
                } else if (res.data.length >= 1) {
                  if(res.data[0].type == 1){
                    setData133(defaultdata[4]);
                    setEditButton133(true);
                    setIcon133(<EventBusy />);
                    setStatus133("ไม่ว่าง");
                    return;
                  }
                  setData133(res.data[0]);
                  setEditButton133(true);
                  setStatus133("ไม่ว่าง");
                  setIcon133(<EventBusy />);
                }
              } else {
                setData133(defaultdata[2]);
                setEditButton133(true);
                setIcon133(<EventBusy />);
                setStatus133("ไม่ว่าง");
                return;
              }
            });
            break;
          case "134":
            axios.get(`${holidaysURL}`).then((resdata) => {
              const holidaysearch = startDate.toLocaleDateString("th-TH", {
                year: "numeric",
                month: "long",
                day: "numeric",
              });
              const holidays = resdata.data.map((item) => item.date);
              const arrayIncludes = holidays.includes(holidaysearch);
              if (arrayIncludes !== true) {
                if (res.data.length === 0) {
                  if (startDate.getDay() === 0 || startDate.getDay() === 6) {
                    setData134(defaultdata[1]);
                    setEditButton134(true);
                    setIcon134(<EventBusy />);
                    setStatus134("ไม่ว่าง");
                    return;
                  } else {
                    setData134(defaultdata[0]);
                    setEditButton134(false);
                    setStatus134("ว่าง");
                    return;
                  }
                } else if (res.data.length >= 1) {
                  if(res.data[0].type == 1){
                    setData134(defaultdata[4]);
                    setEditButton134(true);
                    setIcon134(<EventBusy />);
                    setStatus134("ไม่ว่าง");
                    return;
                  }
                  setData134(res.data[0]);
                  setEditButton134(true);
                  setStatus134("ไม่ว่าง");
                  setIcon134(<EventBusy />);
                }
              } else {
                setData134(defaultdata[2]);
                setEditButton134(true);
                setIcon134(<EventBusy />);
                setStatus134("ไม่ว่าง");
                return;
              }
            });
            break;
          case "135":
            axios.get(`${holidaysURL}`).then((resdata) => {
              const holidaysearch = startDate.toLocaleDateString("th-TH", {
                year: "numeric",
                month: "long",
                day: "numeric",
              });
              const holidays = resdata.data.map((item) => item.date);
              const arrayIncludes = holidays.includes(holidaysearch);
              if (arrayIncludes !== true) {
                if (res.data.length === 0) {
                  if (startDate.getDay() === 0 || startDate.getDay() === 6) {
                    setData135(defaultdata[1]);
                    setEditButton135(true);
                    setIcon135(<EventBusy />);
                    setStatus135("ไม่ว่าง");
                    return;
                  } else {
                    setData135(defaultdata[0]);
                    setEditButton135(false);
                    setStatus135("ว่าง");
                    return;
                  }
                } else if (res.data.length >= 1) {
                  if(res.data[0].type == 1){
                    setData135(defaultdata[4]);
                    setEditButton135(true);
                    setIcon135(<EventBusy />);
                    setStatus135("ไม่ว่าง");
                    return;
                  }
                  setData135(res.data[0]);
                  setEditButton135(true);
                  setStatus135("ไม่ว่าง");
                  setIcon135(<EventBusy />);
                }
              } else {
                setData135(defaultdata[2]);
                setEditButton135(true);
                setIcon135(<EventBusy />);
                setStatus135("ไม่ว่าง");
                return;
              }
            });
            break;
          case "136":
            axios.get(`${holidaysURL}`).then((resdata) => {
              const holidaysearch = startDate.toLocaleDateString("th-TH", {
                year: "numeric",
                month: "long",
                day: "numeric",
              });
              const holidays = resdata.data.map((item) => item.date);
              const arrayIncludes = holidays.includes(holidaysearch);
              if (arrayIncludes !== true) {
                if (res.data.length === 0) {
                  if (startDate.getDay() === 0 || startDate.getDay() === 6) {
                    setData136(defaultdata[1]);
                    setEditButton136(true);
                    setIcon136(<EventBusy />);
                    setStatus136("ไม่ว่าง");
                    return;
                  } else {
                    setData136(defaultdata[0]);
                    setEditButton136(false);
                    setStatus136("ว่าง");
                    return;
                  }
                } else if (res.data.length >= 1) {
                  if(res.data[0].type == 1){
                    setData136(defaultdata[4]);
                    setEditButton136(true);
                    setIcon136(<EventBusy />);
                    setStatus136("ไม่ว่าง");
                    return;
                  }
                  setData136(res.data[0]);
                  setEditButton136(true);
                  setIcon136(<EventBusy />);
                  setStatus136("ไม่ว่าง");
                  return;
                }
              } else {
                setData136(defaultdata[2]);
                setEditButton136(true);
                setIcon136(<EventBusy />);
                setStatus136("ไม่ว่าง");
                return;
              }
            });
            break;
          case "141":
            axios.get(`${holidaysURL}`).then((resdata) => {
              const holidaysearch = startDate.toLocaleDateString("th-TH", {
                year: "numeric",
                month: "long",
                day: "numeric",
              });
              const holidays = resdata.data.map((item) => item.date);
              const arrayIncludes = holidays.includes(holidaysearch);
              if (arrayIncludes !== true) {
                if (res.data.length === 0) {
                  if (startDate.getDay() === 0 || startDate.getDay() === 6) {
                    setData141(defaultdata[1]);
                    setEditButton141(true);
                    setIcon141(<EventBusy />);
                    setStatus141("ไม่ว่าง");
                    return;
                  } else {
                    setData141(defaultdata[0]);
                    setEditButton141(false);
                    setStatus141("ว่าง");
                    return;
                  }
                } else if (res.data.length >= 1) {
                  if(res.data[0].type == 1){
                    setData141(defaultdata[4]);
                    setEditButton141(true);
                    setIcon141(<EventBusy />);
                    setStatus141("ไม่ว่าง");
                    return;
                  }
                  setData141(res.data[0]);
                  setEditButton141(true);
                  setIcon141(<EventBusy />);
                  setStatus141("ไม่ว่าง");
                  return;
                }
              } else {
                setData141(defaultdata[2]);
                setEditButton141(true);
                setIcon141(<EventBusy />);
                setStatus141("ไม่ว่าง");
                return;
              }
            });
            break;
          case "142":
            axios.get(`${holidaysURL}`).then((resdata) => {
              const holidaysearch = startDate.toLocaleDateString("th-TH", {
                year: "numeric",
                month: "long",
                day: "numeric",
              });
              const holidays = resdata.data.map((item) => item.date);
              const arrayIncludes = holidays.includes(holidaysearch);
              if (arrayIncludes !== true) {
                if (res.data.length === 0) {
                  if (startDate.getDay() === 0 || startDate.getDay() === 6) {
                    setData142(defaultdata[1]);
                    setEditButton142(true);
                    setIcon142(<EventBusy />);
                    setStatus142("ไม่ว่าง");
                    return;
                  } else {
                    setData142(defaultdata[0]);
                    setEditButton142(false);
                    setStatus142("ว่าง");
                    return;
                  }
                } else if (res.data.length >= 1) {
                  if(res.data[0].type == 1){
                    setData142(defaultdata[4]);
                    setEditButton142(true);
                    setIcon142(<EventBusy />);
                    setStatus142("ไม่ว่าง");
                    return;
                  }
                  setData142(res.data[0]);
                  setEditButton142(true);
                  setIcon142(<EventBusy />);
                  setStatus142("ไม่ว่าง");
                  return;
                }
              } else {
                setData142(defaultdata[2]);
                setEditButton142(true);
                setIcon142(<EventBusy />);
                setStatus142("ไม่ว่าง");
                return;
              }
            });
            break;
          case "143":
            axios.get(`${holidaysURL}`).then((resdata) => {
              const holidaysearch = startDate.toLocaleDateString("th-TH", {
                year: "numeric",
                month: "long",
                day: "numeric",
              });
              const holidays = resdata.data.map((item) => item.date);
              const arrayIncludes = holidays.includes(holidaysearch);
              if (arrayIncludes !== true) {
                if (res.data.length === 0) {
                  if (startDate.getDay() === 0 || startDate.getDay() === 6) {
                    setData143(defaultdata[1]);
                    setEditButton143(true);
                    setIcon143(<EventBusy />);
                    setStatus143("ไม่ว่าง");
                    return;
                  } else {
                    setData143(defaultdata[0]);
                    setEditButton143(false);
                    setStatus143("ว่าง");
                    return;
                  }
                } else if (res.data.length >= 1) {
                  if(res.data[0].type == 1){
                    setData143(defaultdata[4]);
                    setEditButton143(true);
                    setIcon143(<EventBusy />);
                    setStatus143("ไม่ว่าง");
                    return;
                  }
                  setData143(res.data[0]);
                  setEditButton143(true);
                  setStatus143("ไม่ว่าง");
                  setIcon143(<EventBusy />);
                }
              } else {
                setData143(defaultdata[2]);
                setEditButton143(true);
                setIcon143(<EventBusy />);
                setStatus143("ไม่ว่าง");
                return;
              }
            });
            break;
          case "144":
            axios.get(`${holidaysURL}`).then((resdata) => {
              const holidaysearch = startDate.toLocaleDateString("th-TH", {
                year: "numeric",
                month: "long",
                day: "numeric",
              });
              const holidays = resdata.data.map((item) => item.date);
              const arrayIncludes = holidays.includes(holidaysearch);
              if (arrayIncludes !== true) {
                if (res.data.length === 0) {
                  if (startDate.getDay() === 0 || startDate.getDay() === 6) {
                    setData144(defaultdata[1]);
                    setEditButton144(true);
                    setIcon144(<EventBusy />);
                    setStatus144("ไม่ว่าง");
                    return;
                  } else {
                    setData144(defaultdata[0]);
                    setEditButton144(false);
                    setStatus144("ว่าง");
                    return;
                  }
                } else if (res.data.length >= 1) {
                  if(res.data[0].type == 1){
                    setData144(defaultdata[4]);
                    setEditButton144(true);
                    setIcon144(<EventBusy />);
                    setStatus144("ไม่ว่าง");
                    return;
                  }
                  setData144(res.data[0]);
                  setEditButton144(true);
                  setStatus144("ไม่ว่าง");
                  setIcon144(<EventBusy />);
                }
              } else {
                setData144(defaultdata[2]);
                setEditButton144(true);
                setIcon144(<EventBusy />);
                setStatus144("ไม่ว่าง");
                return;
              }
            });
            break;
          case "145":
            axios.get(`${holidaysURL}`).then((resdata) => {
              const holidaysearch = startDate.toLocaleDateString("th-TH", {
                year: "numeric",
                month: "long",
                day: "numeric",
              });
              const holidays = resdata.data.map((item) => item.date);
              const arrayIncludes = holidays.includes(holidaysearch);
              if (arrayIncludes !== true) {
                if (res.data.length === 0) {
                  if (startDate.getDay() === 0 || startDate.getDay() === 6) {
                    setData145(defaultdata[1]);
                    setEditButton145(true);
                    setIcon145(<EventBusy />);
                    setStatus145("ไม่ว่าง");
                    return;
                  } else {
                    setData145(defaultdata[0]);
                    setEditButton145(false);
                    setStatus145("ว่าง");
                    return;
                  }
                } else if (res.data.length >= 1) {
                  if(res.data[0].type == 1){
                    setData145(defaultdata[4]);
                    setEditButton145(true);
                    setIcon145(<EventBusy />);
                    setStatus145("ไม่ว่าง");
                    return;
                  }
                  setData145(res.data[0]);
                  setEditButton145(true);
                  setStatus145("ไม่ว่าง");
                  setIcon145(<EventBusy />);
                }
              } else {
                setData145(defaultdata[2]);
                setEditButton145(true);
                setIcon145(<EventBusy />);
                setStatus145("ไม่ว่าง");
                return;
              }
            });
            break;
          case "146":
            axios.get(`${holidaysURL}`).then((resdata) => {
              const holidaysearch = startDate.toLocaleDateString("th-TH", {
                year: "numeric",
                month: "long",
                day: "numeric",
              });
              const holidays = resdata.data.map((item) => item.date);
              const arrayIncludes = holidays.includes(holidaysearch);
              if (arrayIncludes !== true) {
                if (res.data.length === 0) {
                  if (startDate.getDay() === 0 || startDate.getDay() === 6) {
                    setData146(defaultdata[1]);
                    setEditButton146(true);
                    setIcon146(<EventBusy />);
                    setStatus146("ไม่ว่าง");
                    return;
                  } else {
                    setData146(defaultdata[0]);
                    setEditButton146(false);
                    setStatus146("ว่าง");
                    return;
                  }
                } else if (res.data.length >= 1) {
                  if(res.data[0].type == 1){
                    setData146(defaultdata[4]);
                    setEditButton146(true);
                    setIcon146(<EventBusy />);
                    setStatus146("ไม่ว่าง");
                    return;
                  }
                  setData146(res.data[0]);
                  setEditButton146(true);
                  setStatus146("ไม่ว่าง");
                  setIcon146(<EventBusy />);
                }
              } else {
                setData146(defaultdata[2]);
                setEditButton146(true);
                setIcon146(<EventBusy />);
                setStatus146("ไม่ว่าง");
                return;
              }
            });
            break;
          default:
            console.log("fail");
        }
      };

      setdata(
        res91,
        "91",
        "08:50",
        res91.data.map((item) => item.TN)
      );
      setdata(
        res92,
        "92",
        "08:50",
        res92.data.map((item) => item.TN)
      );
      setdata(
        res93,
        "93",
        "08:50",
        res93.data.map((item) => item.TN)
      );
      setdata(
        res94,
        "94",
        "08:50",
        res94.data.map((item) => item.TN)
      );
      setdata(
        res95,
        "95",
        "08:50",
        res95.data.map((item) => item.TN)
      );
      setdata(
        res96,
        "96",
        "08:50",
        res96.data.map((item) => item.TN)
      );
      setdata(
        res101,
        "101",
        "09:55",
        res101.data.map((item) => item.TN)
      );
      setdata(
        res102,
        "102",
        "09:55",
        res102.data.map((item) => item.TN)
      );
      setdata(
        res103,
        "103",
        "09:55",
        res103.data.map((item) => item.TN)
      );
      setdata(
        res104,
        "104",
        "09:55",
        res104.data.map((item) => item.TN)
      );
      setdata(
        res105,
        "105",
        "09:55",
        res105.data.map((item) => item.TN)
      );
      setdata(
        res106,
        "106",
        "09:55",
        res106.data.map((item) => item.TN)
      );
      setdata(
        res111,
        "111",
        "11:00",
        res111.data.map((item) => item.TN)
      );
      setdata(
        res112,
        "112",
        "11:00",
        res112.data.map((item) => item.TN)
      );
      setdata(
        res113,
        "113",
        "11:00",
        res113.data.map((item) => item.TN)
      );
      setdata(
        res114,
        "114",
        "11:00",
        res114.data.map((item) => item.TN)
      );
      setdata(
        res115,
        "115",
        "11:00",
        res115.data.map((item) => item.TN)
      );
      setdata(
        res116,
        "116",
        "11:00",
        res116.data.map((item) => item.TN)
      );
      setdata(
        res131,
        "131",
        "13:30",
        res131.data.map((item) => item.TN)
      );
      setdata(
        res132,
        "132",
        "13:30",
        res132.data.map((item) => item.TN)
      );
      setdata(
        res133,
        "133",
        "13:30",
        res133.data.map((item) => item.TN)
      );
      setdata(
        res134,
        "134",
        "13:30",
        res134.data.map((item) => item.TN)
      );
      setdata(
        res135,
        "135",
        "13:30",
        res135.data.map((item) => item.TN)
      );
      setdata(
        res136,
        "136",
        "13:30",
        res136.data.map((item) => item.TN)
      );
      setdata(
        res141,
        "141",
        "14:45",
        res141.data.map((item) => item.TN)
      );
      setdata(
        res142,
        "142",
        "14:45",
        res142.data.map((item) => item.TN)
      );
      setdata(
        res143,
        "143",
        "14:45",
        res143.data.map((item) => item.TN)
      );
      setdata(
        res144,
        "144",
        "14:45",
        res144.data.map((item) => item.TN)
      );
      setdata(
        res145,
        "145",
        "14:45",
        res145.data.map((item) => item.TN)
      );
      setdata(
        res146,
        "146",
        "14:45",
        res146.data.map((item) => item.TN)
      );
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getdata();
  }, [startDate, reData91, reData92, reData93, reData94, reData95, reData96, reData101, reData102, reData103, reData104, reData105, reData106, reData111, reData112, reData113, reData114, reData115, reData116, reData131, reData132, reData133, reData134, reData135, reData136, reData141, reData142, reData143, reData144, reData145, reData146, URL]);

  //api

  const handleOpen = () => {
    Swal.fire({
      icon: "info",
      title: "โปรดทราบ",
      text: "กรุณาเข้าสู่ระบบก่อนใช้บริการ หากยังไม่มีรหัส ให้ติดต่อเจ้าหน้าที่",
      showConfirmButton: false,
      timer: 2000,
    });
  };

  return (
    <>
      <CssBaseline />
      <Container>
        <Box>
          <Paper>
            <div style={{ textAlign: "center" }}>
              <h2 style={{ margin: "10px 10px 10px 10px" }}>
                <DatePicker
                  onChange={(Date) => {
                    setStartDate(Date);
                    return;
                  }}
                  value={startDate}
                  format="dd-MM-y"
                  locale="th-TH"
                  calendarClassName="calendarclass"
                  className="picker"
                />
                <span style={{ marginLeft: "10px", fontSize: "small" }}>{dayname}</span>
              </h2>
            </div>
            <div className="testtest">
              <table className="ondate">
                <tbody>
                  {/* หัวข้อ */}
                  <tr className="head">
                    <th className="headtime">เวลา</th>
                    <th className="headname">ชื่อ-สกุล</th>
                    <th className="headservice">หัตถการ</th>
                    <th className="headprovider">ผู้ให้บริการ</th>
                    <th className="headstatus">สถานะ</th>
                  </tr>
                  {/* หัวข้อ */}
                  {/* 08:50 */}
                  <tr className="bodynine">
                    <td className="bodytime">08:50 น.</td>
                    <td className="bodyname">
                      {data91.prefix}
                      {data91.fname} {data91.lname}
                    </td>
                    <td className="bodyservice">{data91.service}</td>
                    <td className="bodyprovider">หญิง</td>
                    <td className="bodystatus">
                      <Button
                        variant="contained"
                        color="success"
                        size="small"
                        className="btnstatus"
                        startIcon={icon91}
                        disabled={editButton91}
                        onClick={() => {
                          handleOpen();
                        }}
                        style={{ margin: "0 0 0 4px" }}
                      >
                        {status91}
                      </Button>
                    </td>
                  </tr>
                  <tr className="bodynine">
                    <td className="bodytime">08:50 น.</td>
                    <td className="bodyname">
                      {data92.prefix}
                      {data92.fname} {data92.lname}
                    </td>
                    <td className="bodyservice">{data92.service}</td>
                    <td className="bodyprovider">หญิง</td>
                    <td className="bodystatus">
                      <Button
                        variant="contained"
                        color="success"
                        size="small"
                        className="btnstatus"
                        startIcon={icon92}
                        disabled={editButton92}
                        onClick={() => {
                          handleOpen();
                        }}
                        style={{ margin: "0 0 0 4px" }}
                      >
                        {status92}
                      </Button>
                    </td>
                  </tr>
                  <tr className="bodynine">
                    <td className="bodytime">08:50 น.</td>
                    <td className="bodyname">
                      {data93.prefix}
                      {data93.fname} {data93.lname}
                    </td>
                    <td className="bodyservice">{data93.service}</td>
                    <td className="bodyprovider">หญิง</td>
                    <td className="bodystatus">
                      <Button
                        variant="contained"
                        color="success"
                        size="small"
                        className="btnstatus"
                        startIcon={icon93}
                        disabled={editButton93}
                        onClick={() => {
                          handleOpen();
                        }}
                        style={{ margin: "0 0 0 4px" }}
                      >
                        {status93}
                      </Button>
                    </td>
                  </tr>
                  <tr className="bodynine">
                    <td className="bodytime">08:50 น.</td>
                    <td className="bodyname">
                      {data94.prefix}
                      {data94.fname} {data94.lname}
                    </td>
                    <td className="bodyservice">{data94.service}</td>
                    <td className="bodyprovider">หญิง</td>
                    <td className="bodystatus">
                      <Button
                        variant="contained"
                        color="success"
                        size="small"
                        className="btnstatus"
                        startIcon={icon94}
                        disabled={editButton94}
                        onClick={() => {
                          handleOpen();
                        }}
                        style={{ margin: "0 0 0 4px" }}
                      >
                        {status94}
                      </Button>
                    </td>
                  </tr>
                  <tr className="bodynine">
                    <td className="bodytime">08:50 น.</td>
                    <td className="bodyname">
                      {data95.prefix}
                      {data95.fname} {data95.lname}
                    </td>
                    <td className="bodyservice">{data95.service}</td>
                    <td className="bodyprovider">หญิง</td>
                    <td className="bodystatus">
                      <Button
                        variant="contained"
                        color="success"
                        size="small"
                        className="btnstatus"
                        startIcon={icon95}
                        disabled={editButton95}
                        onClick={() => {
                          handleOpen();
                        }}
                        style={{ margin: "0 0 0 4px" }}
                      >
                        {status95}
                      </Button>
                    </td>
                  </tr>
                  <tr className="bodynine" style={{ display: "none" }}>
                    <td className="bodytime">08:50 น.</td>
                    <td className="bodyname">
                      {data96.prefix}
                      {data96.fname} {data96.lname}
                    </td>
                    <td className="bodyservice">{data96.service}</td>
                    <td className="bodyprovider">หญิง</td>
                    <td className="bodystatus">
                      <Button
                        variant="contained"
                        color="success"
                        size="small"
                        className="btnstatus"
                        startIcon={icon96}
                        disabled={editButton96}
                        onClick={() => {
                          handleOpen();
                        }}
                        style={{ margin: "0 0 0 4px" }}
                      >
                        {status96}
                      </Button>
                    </td>
                  </tr>
                  {/* 08:50 */}
                  {/* 09:55 */}
                  <tr className="bodyten">
                    <td className="bodytime">09:55 น.</td>
                    <td className="bodyname">
                      {data101.prefix}
                      {data101.fname} {data101.lname}
                    </td>
                    <td className="bodyservice">{data101.service}</td>
                    <td className="bodyprovider">หญิง</td>
                    <td className="bodystatus">
                      <Button
                        variant="contained"
                        color="success"
                        size="small"
                        className="btnstatus"
                        startIcon={icon101}
                        disabled={editButton101}
                        onClick={() => {
                          handleOpen();
                        }}
                        style={{ margin: "0 0 0 4px" }}
                      >
                        {status101}
                      </Button>
                    </td>
                  </tr>
                  <tr className="bodyten">
                    <td className="bodytime">09:55 น.</td>
                    <td className="bodyname">
                      {data102.prefix}
                      {data102.fname} {data102.lname}
                    </td>
                    <td className="bodyservice">{data102.service}</td>
                    <td className="bodyprovider">หญิง</td>
                    <td className="bodystatus">
                      <Button
                        variant="contained"
                        color="success"
                        size="small"
                        className="btnstatus"
                        startIcon={icon102}
                        disabled={editButton102}
                        onClick={() => {
                          handleOpen();
                        }}
                        style={{ margin: "0 0 0 4px" }}
                      >
                        {status102}
                      </Button>
                    </td>
                  </tr>
                  <tr className="bodyten">
                    <td className="bodytime">09:55 น.</td>
                    <td className="bodyname">
                      {data103.prefix}
                      {data103.fname} {data103.lname}
                    </td>
                    <td className="bodyservice">{data103.service}</td>
                    <td className="bodyprovider">หญิง</td>
                    <td className="bodystatus">
                      <Button
                        variant="contained"
                        color="success"
                        size="small"
                        className="btnstatus"
                        startIcon={icon103}
                        disabled={editButton103}
                        onClick={() => {
                          handleOpen();
                        }}
                        style={{ margin: "0 0 0 4px" }}
                      >
                        {status103}
                      </Button>
                    </td>
                  </tr>
                  <tr className="bodyten">
                    <td className="bodytime">09:55 น.</td>
                    <td className="bodyname">
                      {data104.prefix}
                      {data104.fname} {data104.lname}
                    </td>
                    <td className="bodyservice">{data104.service}</td>
                    <td className="bodyprovider">หญิง</td>
                    <td className="bodystatus">
                      <Button
                        variant="contained"
                        color="success"
                        size="small"
                        className="btnstatus"
                        startIcon={icon104}
                        disabled={editButton104}
                        onClick={() => {
                          handleOpen();
                        }}
                        style={{ margin: "0 0 0 4px" }}
                      >
                        {status104}
                      </Button>
                    </td>
                  </tr>
                  <tr className="bodyten">
                    <td className="bodytime">09:55 น.</td>
                    <td className="bodyname">
                      {data105.prefix}
                      {data105.fname} {data105.lname}
                    </td>
                    <td className="bodyservice">{data105.service}</td>
                    <td className="bodyprovider">หญิง</td>
                    <td className="bodystatus">
                      <Button
                        variant="contained"
                        color="success"
                        size="small"
                        className="btnstatus"
                        startIcon={icon105}
                        disabled={editButton105}
                        onClick={() => {
                          handleOpen();
                        }}
                        style={{ margin: "0 0 0 4px" }}
                      >
                        {status105}
                      </Button>
                    </td>
                  </tr>
                  <tr className="bodyten">
                    <td className="bodytime">09:55 น.</td>
                    <td className="bodyname">
                      {data106.prefix}
                      {data106.fname} {data106.lname}
                    </td>
                    <td className="bodyservice">{data106.service}</td>
                    <td className="bodyprovider">หญิง</td>
                    <td className="bodystatus">
                      <Button
                        variant="contained"
                        color="success"
                        size="small"
                        className="btnstatus"
                        startIcon={icon106}
                        disabled={editButton106}
                        onClick={() => {
                          handleOpen();
                        }}
                        style={{ margin: "0 0 0 4px" }}
                      >
                        {status106}
                      </Button>
                    </td>
                  </tr>
                  {/* 10.15 */}
                  {/* 11.00 */}
                  <tr className="bodyeleven">
                    <td className="bodytime">{time11[0]} น.</td>
                    <td className="bodyname">
                      {data111.prefix}
                      {data111.fname} {data111.lname}
                    </td>
                    <td className="bodyservice">{data111.service}</td>
                    <td className="bodyprovider">หญิง</td>
                    <td className="bodystatus">
                      <Button
                        variant="contained"
                        color="success"
                        size="small"
                        className="btnstatus"
                        startIcon={icon111}
                        disabled={editButton111}
                        onClick={() => {
                          handleOpen();
                        }}
                        style={{ margin: "0 0 0 4px" }}
                      >
                        {status111}
                      </Button>
                    </td>
                  </tr>
                  <tr className="bodyeleven">
                    <td className="bodytime">{time11[0]} น.</td>
                    <td className="bodyname">
                      {data112.prefix}
                      {data112.fname} {data112.lname}
                    </td>
                    <td className="bodyservice">{data112.service}</td>
                    <td className="bodyprovider">หญิง</td>
                    <td className="bodystatus">
                      <Button
                        variant="contained"
                        color="success"
                        size="small"
                        className="btnstatus"
                        startIcon={icon112}
                        disabled={editButton112}
                        onClick={() => {
                          handleOpen();
                        }}
                        style={{ margin: "0 0 0 4px" }}
                      >
                        {status112}
                      </Button>
                    </td>
                  </tr>
                  <tr className="bodyeleven">
                    <td className="bodytime">{time11[0]} น.</td>
                    <td className="bodyname">
                      {data113.prefix}
                      {data113.fname} {data113.lname}
                    </td>
                    <td className="bodyservice">{data113.service}</td>
                    <td className="bodyprovider">หญิง</td>
                    <td className="bodystatus">
                      <Button
                        variant="contained"
                        color="success"
                        size="small"
                        className="btnstatus"
                        startIcon={icon113}
                        disabled={editButton113}
                        onClick={() => {
                          handleOpen();
                        }}
                        style={{ margin: "0 0 0 4px" }}
                      >
                        {status113}
                      </Button>
                    </td>
                  </tr>
                  <tr className="bodyeleven">
                    <td className="bodytime">{time11[0]} น.</td>
                    <td className="bodyname">
                      {data114.prefix}
                      {data114.fname} {data114.lname}
                    </td>
                    <td className="bodyservice">{data114.service}</td>
                    <td className="bodyprovider">หญิง</td>
                    <td className="bodystatus">
                      <Button
                        variant="contained"
                        color="success"
                        size="small"
                        className="btnstatus"
                        startIcon={icon114}
                        disabled={editButton114}
                        onClick={() => {
                          handleOpen();
                        }}
                        style={{ margin: "0 0 0 4px" }}
                      >
                        {status114}
                      </Button>
                    </td>
                  </tr>
                  <tr className="bodyeleven">
                    <td className="bodytime">{time11[0]} น.</td>
                    <td className="bodyname">
                      {data115.prefix}
                      {data115.fname} {data115.lname}
                    </td>
                    <td className="bodyservice">{data115.service}</td>
                    <td className="bodyprovider">หญิง</td>
                    <td className="bodystatus">
                      <Button
                        variant="contained"
                        color="success"
                        size="small"
                        className="btnstatus"
                        startIcon={icon115}
                        disabled={editButton115}
                        onClick={() => {
                          handleOpen();
                        }}
                        style={{ margin: "0 0 0 4px" }}
                      >
                        {status115}
                      </Button>
                    </td>
                  </tr>
                  <tr className="bodyeleven" style={{ display: "none" }}>
                    <td className="bodytime">{time11[0]} น.</td>
                    <td className="bodyname">
                      {data116.prefix}
                      {data116.fname} {data116.lname}
                    </td>
                    <td className="bodyservice">{data116.service}</td>
                    <td className="bodyprovider">หญิง</td>
                    <td className="bodystatus">
                      <Button
                        variant="contained"
                        color="success"
                        size="small"
                        className="btnstatus"
                        startIcon={icon116}
                        disabled={editButton116}
                        onClick={() => {
                          handleOpen();
                        }}
                        style={{ margin: "0 0 0 4px" }}
                      >
                        {status116}
                      </Button>
                    </td>
                  </tr>
                  {/* 11.00 */}
                  {/* 13.30 */}
                  <tr className="body13">
                    <td className="bodytime">{time13[0]} น.</td>
                    <td className="bodyname">
                      {data131.prefix}
                      {data131.fname} {data131.lname}
                    </td>
                    <td className="bodyservice">{data131.service}</td>
                    <td className="bodyprovider">หญิง</td>
                    <td className="bodystatus">
                      <Button
                        variant="contained"
                        color="success"
                        size="small"
                        className="btnstatus"
                        startIcon={icon131}
                        disabled={editButton131}
                        onClick={() => {
                          handleOpen();
                        }}
                        style={{ margin: "0 0 0 4px" }}
                      >
                        {status131}
                      </Button>
                    </td>
                  </tr>
                  <tr className="body13">
                    <td className="bodytime">{time13[0]} น.</td>
                    <td className="bodyname">
                      {data132.prefix}
                      {data132.fname} {data132.lname}
                    </td>
                    <td className="bodyservice">{data132.service}</td>
                    <td className="bodyprovider">หญิง</td>
                    <td className="bodystatus">
                      <Button
                        variant="contained"
                        color="success"
                        size="small"
                        className="btnstatus"
                        startIcon={icon132}
                        disabled={editButton132}
                        onClick={() => {
                          handleOpen();
                        }}
                        style={{ margin: "0 0 0 4px" }}
                      >
                        {status132}
                      </Button>
                    </td>
                  </tr>
                  <tr className="body13">
                    <td className="bodytime">{time13[0]} น.</td>
                    <td className="bodyname">
                      {data133.prefix}
                      {data133.fname} {data133.lname}
                    </td>
                    <td className="bodyservice">{data133.service}</td>
                    <td className="bodyprovider">หญิง</td>
                    <td className="bodystatus">
                      <Button
                        variant="contained"
                        color="success"
                        size="small"
                        className="btnstatus"
                        startIcon={icon133}
                        disabled={editButton133}
                        onClick={() => {
                          handleOpen();
                        }}
                        style={{ margin: "0 0 0 4px" }}
                      >
                        {status133}
                      </Button>
                    </td>
                  </tr>
                  <tr className="body13">
                    <td className="bodytime">{time13[0]} น.</td>
                    <td className="bodyname">
                      {data134.prefix}
                      {data134.fname} {data134.lname}
                    </td>
                    <td className="bodyservice">{data134.service}</td>
                    <td className="bodyprovider">หญิง</td>
                    <td className="bodystatus">
                      <Button
                        variant="contained"
                        color="success"
                        size="small"
                        className="btnstatus"
                        startIcon={icon134}
                        disabled={editButton134}
                        onClick={() => {
                          handleOpen();
                        }}
                        style={{ margin: "0 0 0 4px" }}
                      >
                        {status134}
                      </Button>
                    </td>
                  </tr>
                  <tr className="body13">
                    <td className="bodytime">{time13[0]} น.</td>
                    <td className="bodyname">
                      {data135.prefix}
                      {data135.fname} {data135.lname}
                    </td>
                    <td className="bodyservice">{data135.service}</td>
                    <td className="bodyprovider">หญิง</td>
                    <td className="bodystatus">
                      <Button
                        variant="contained"
                        color="success"
                        size="small"
                        className="btnstatus"
                        startIcon={icon135}
                        disabled={editButton135}
                        onClick={() => {
                          handleOpen();
                        }}
                        style={{ margin: "0 0 0 4px" }}
                      >
                        {status135}
                      </Button>
                    </td>
                  </tr>
                  <tr className="body13">
                    <td className="bodytime">{time13[0]} น.</td>
                    <td className="bodyname">
                      {data136.prefix}
                      {data136.fname} {data136.lname}
                    </td>
                    <td className="bodyservice">{data136.service}</td>
                    <td className="bodyprovider">หญิง</td>
                    <td className="bodystatus">
                      <Button
                        variant="contained"
                        color="success"
                        size="small"
                        className="btnstatus"
                        startIcon={icon136}
                        disabled={editButton136}
                        onClick={() => {
                          handleOpen();
                        }}
                        style={{ margin: "0 0 0 4px" }}
                      >
                        {status136}
                      </Button>
                    </td>
                  </tr>
                  {/* 13.30 */}
                  {/* 14.45 */}
                  <tr className="body14">
                    <td className="bodytime">{time14[0]} น.</td>
                    <td className="bodyname">
                      {data141.prefix}
                      {data141.fname} {data141.lname}
                    </td>
                    <td className="bodyservice">{data141.service}</td>
                    <td className="bodyprovider">หญิง</td>
                    <td className="bodystatus">
                      <Button
                        variant="contained"
                        color="success"
                        size="small"
                        className="btnstatus"
                        startIcon={icon141}
                        disabled={editButton141}
                        onClick={() => {
                          handleOpen();
                        }}
                        style={{ margin: "0 0 0 4px" }}
                      >
                        {status141}
                      </Button>
                    </td>
                  </tr>
                  <tr className="body14">
                    <td className="bodytime">{time14[0]} น.</td>
                    <td className="bodyname">
                      {data142.prefix}
                      {data142.fname} {data142.lname}
                    </td>
                    <td className="bodyservice">{data142.service}</td>
                    <td className="bodyprovider">หญิง</td>
                    <td className="bodystatus">
                      <Button
                        variant="contained"
                        color="success"
                        size="small"
                        className="btnstatus"
                        startIcon={icon142}
                        disabled={editButton142}
                        onClick={() => {
                          handleOpen();
                        }}
                        style={{ margin: "0 0 0 4px" }}
                      >
                        {status142}
                      </Button>
                    </td>
                  </tr>
                  <tr className="body14">
                    <td className="bodytime">{time14[0]} น.</td>
                    <td className="bodyname">
                      {data143.prefix}
                      {data143.fname} {data143.lname}
                    </td>
                    <td className="bodyservice">{data143.service}</td>
                    <td className="bodyprovider">หญิง</td>
                    <td className="bodystatus">
                      <Button
                        variant="contained"
                        color="success"
                        size="small"
                        className="btnstatus"
                        startIcon={icon143}
                        disabled={editButton143}
                        onClick={() => {
                          handleOpen();
                        }}
                        style={{ margin: "0 0 0 4px" }}
                      >
                        {status143}
                      </Button>
                    </td>
                  </tr>
                  <tr className="body14">
                    <td className="bodytime">{time14[0]} น.</td>
                    <td className="bodyname">
                      {data144.prefix}
                      {data144.fname} {data144.lname}
                    </td>
                    <td className="bodyservice">{data144.service}</td>
                    <td className="bodyprovider">หญิง</td>
                    <td className="bodystatus">
                      <Button
                        variant="contained"
                        color="success"
                        size="small"
                        className="btnstatus"
                        startIcon={icon144}
                        disabled={editButton144}
                        onClick={() => {
                          handleOpen();
                        }}
                        style={{ margin: "0 0 0 4px" }}
                      >
                        {status144}
                      </Button>
                    </td>
                  </tr>
                  <tr className="body14">
                    <td className="bodytime">{time14[0]} น.</td>
                    <td className="bodyname">
                      {data145.prefix}
                      {data145.fname} {data145.lname}
                    </td>
                    <td className="bodyservice">{data145.service}</td>
                    <td className="bodyprovider">หญิง</td>
                    <td className="bodystatus">
                      <Button
                        variant="contained"
                        color="success"
                        size="small"
                        className="btnstatus"
                        startIcon={icon145}
                        disabled={editButton145}
                        onClick={() => {
                          handleOpen();
                        }}
                        style={{ margin: "0 0 0 4px" }}
                      >
                        {status145}
                      </Button>
                    </td>
                  </tr>
                  <tr className="body14" style={{ display: "none" }}>
                    <td className="bodytime">{time14[0]} น.</td>
                    <td className="bodyname">
                      {data146.prefix}
                      {data146.fname} {data146.lname}
                    </td>
                    <td className="bodyservice">{data146.service}</td>
                    <td className="bodyprovider">หญิง</td>
                    <td className="bodystatus">
                      <Button
                        variant="contained"
                        color="success"
                        size="small"
                        className="btnstatus"
                        startIcon={icon146}
                        disabled={editButton146}
                        onClick={() => {
                          handleOpen();
                        }}
                        style={{ margin: "0 0 0 4px" }}
                      >
                        {status146}
                      </Button>
                    </td>
                  </tr>
                  {/* 14.45 */}
                </tbody>
              </table>
            </div>
          </Paper>
        </Box>
      </Container>
    </>
  );
}
